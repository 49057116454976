* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.facebook-iframe {
  width: 250px;
  height: 450px;
}

@media screen and (min-width: 640px) {
  .facebook-iframe {
    width: 500px;
    height: 350px;
  }
}
